<template>
  <div class="mt--8 pb-5 container users-edit vh-100" v-if="profile != null">
    <div class="card bg-secondary border-0">
      <b-row class="profile-edit">
        <b-col lg="10" class="mx-auto">
          <h1 class="text-center">Profile Edit</h1>
          <b-row class="border-bottom sticky-top bg-white page-header">
            <div class="d-flex align-items-center">
              <b-link @click="$router.go(-1)">
                <font-awesome-icon icon="arrow-left-long" />
              </b-link>
              <h5 class="text-uppercase p-0 my-3 mx-2 flex-grow-1">Back</h5>
              <b-button @click.prevent="saveSettings">Update</b-button>
            </div>
          </b-row>
          <b-form>
            <b-row>
              <b-col lg="12">
                <b-form-group
                  id="input-group-1"
                  label="Username"
                  label-for="username"
                  description="We'll never share your username with anyone else."
                >
                  <b-form-input
                    id="username"
                    name="username"
                    v-model="profile.username"
                    type="text"
                    placeholder="Enter username"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12">
                <b-form-group
                  id="input-group-2"
                  label="Email"
                  label-for="email"
                  description="We'll never share your email with anyone else."
                >
                  <b-form-input
                    id="email"
                    name="email"
                    v-model="profile.email"
                    type="email"
                    placeholder="Enter email"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12">
                <b-form-group
                  id="input-group-4"
                  label="New password"
                  label-for="password"
                >
                  <b-form-input
                    id="password"
                    name="password"
                    v-model="password"
                    type="password"
                    placeholder="Enter password"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import UserService from "../../services/user.service";
export default {
  name: "ProfileEdit",
  data: function () {
    return {
      profile: {
        username: "",
        email: ""
      },
      password: null,
      errors: {},
    };
  },
  mounted() {
    this.loadProfile();
  },
  methods: {
     logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    async loadProfile() {
      const response = await UserService.getUserProfile("profile");
      if (response.data == '') {
        this.logOut()
      }
      this.profile = response.data?.user;
    },
    async saveSettings() {
      this.errors = {};
      const response = await UserService.updateProfile("profile/update", {
        username: this.profile.username,
        email: this.profile.email,
        password: this.password,
      });
      if (response.data == '') {
        this.logOut()
      }
      if (response) {
        this.user = response.data.user;
      }
    },
  },
};
</script>
<style scoped>
.bg-secondary {
  background-color: #f7fafc !important;
}
</style>
